import Setup from './Setup';
import RouteLayout from './routes/RouteLayout';

function Boot() {
  return (
    <div>
      <Setup>
        <RouteLayout />
      </Setup>
    </div>
  );
}

export default Boot;
