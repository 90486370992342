class SessionStorage {
  get token() {
    try {
      const token = sessionStorage.getItem('token');
      if (!token || token === "undefinde") return null;

      return token;
    } catch (e) {
      return null;
    }
  }
  set token(token) {
    sessionStorage.setItem('token', token);
  }

  get setting() {
    try {
      const setting = sessionStorage.getItem('setting');
      if (!setting || setting === "undefinde") return null;

      return JSON.parse(setting);
    } catch (e) {
      return null;
    }
  }
  set setting(setting) {
    sessionStorage.setItem('setting', JSON.stringify(setting));
  }

  removeStorage() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('setting');
  }

}
const Storage = new SessionStorage();
export default Storage;
