import { Box, Stack } from "@mui/material"
import Banner from "./Banner";
import React from "react";
import Reports from "./reports";
import Charts from "../../../services/Charts.service";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment/moment";
import Session from "../../../services/Session.service";
import SplashLoader from "../../../controls/SplashLoader";

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const currentPage = query.get('page');

    React.useEffect(() => {
        if (!currentPage)
            navigate('?page=User_Engagement', { replace: true });
    }, []);

    if(!currentPage){
        <SplashLoader />
    }

    return (
        <Box p={2}>
            <Stack gap={4}>
                <Banner />
                <Reports currentPage={currentPage}/>
            </Stack>
        </Box>
    )
}
export default Dashboard;