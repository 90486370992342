import * as React from 'react';
import { AppBar, Box, Drawer, IconButton, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import { MenuIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Link from '../../../custom/Link';
import { MoreVert } from '@mui/icons-material';

export default function MenuAppBar() {

    const { token } = useSelector(state => state.app);
    const isLoggedIn = !!token;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (newOpen) => () => {
        setIsMenuOpen(newOpen);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar variant='dense' >
                    {isLoggedIn && (
                        <IconButton
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Intentico Botlytics
                    </Typography>
                    {isLoggedIn && (
                        <div>
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>My account</MenuItem>
                                <MenuItem onClick={handleClose}>Log Out</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer open={isMenuOpen} onClose={toggleDrawer(false)}>
                <Stack p={3} flexGrow={1} justifyContent={"space-between"} sx={{minWidth: "240px"}}>
                    <Stack gap={1}>
                        <Typography variant='h4' color={"primary"} mb={4}>Botlytics</Typography>
                        <Link onClick={() => setIsMenuOpen(false)} to="/_/dashboard?page=Web_Metrics" component={NavLink}>Web Matrics</Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="" component={NavLink}>Whats App Matrics</Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/_/labeler" component={NavLink}>Intent Labeler</Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/_/asr" component={NavLink}>ASR Data</Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/_/query" component={NavLink}>Graph from Query </Link>
                    </Stack>
                    <Stack gap={1}>
                        <Link  onClick={() => setIsMenuOpen(false)} to="" component={NavLink}>Download</Link>
                        <Link  onClick={() => setIsMenuOpen(false)} to="" component={NavLink}>Download Whats App</Link>
                        <Link  onClick={() => setIsMenuOpen(false)} to="" component={NavLink}>Agent App Download</Link>
                    </Stack>
                </Stack>
            </Drawer>
        </Box>
    );
}