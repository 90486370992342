import { Stack } from '@mui/material';
import QueryForm from './form';

const QueryGraph = () => {
    return(
        <Stack p={2}>
            <QueryForm />
        </Stack>
    )
}

export default QueryGraph;