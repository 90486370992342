import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import LabelerTable from "./Table";

const Labeler = () => {
  const [data, setData] = React.useState([]);
  const [sessionId, setSessionId] = React.useState(null);
  const [attempt, setAttempt] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    fetch("https://botanalytics.intentico.ai/backend/get_intent_list")
      .then((response) => response.json())
      .then((result) => {
        // if (result && result.id) {
        //     setSessionId(result.id);
        // }

        if (result && Array.isArray(result) && result.length > 0) {
          result.map((seg) => {
            try {
              const entities = JSON.parse(seg.entity);
              Object.entries(entities).forEach(([key, value]) => {
                if (value === "none" || value === "no_match_vals")
                  entities[key] = null;
              });
              seg.entity = entities;
            } catch (err) {
              seg.entity = {};
            }
          });
          setData(result);
          setLoading(false);
        }
      });
  }, []);

  // React.useEffect(() => {
  //     if (sessionId) {
  //         const timeoutId = setTimeout(() => {
  //             fetch(`https://botanalytics.intentico.ai/backend/getresult?session_id=${sessionId}`)
  //                 .then(response => response.json())
  //                 .then(result => {
  //                     if (result && result.detail === "Query execution failed") {
  //                         if (attempt > 10) {
  //                             setLoading(false)
  //                         } else {
  //                             setAttempt(prev => prev + 1);
  //                         }
  //                     } else if (result && result.rows) {
  //                         setLoading(false);
  //                         const rows = result.rows;
  //                         if (rows && Array.isArray(rows) && rows.length > 0) {
  //                             rows.forEach(r => ({
  //                                 session_id: uuid4(),
  //                                 issue_value: r.issue_value
  //                             }))
  //                             setData(rows);
  //                         }
  //                     }
  //                 })
  //                 .catch(error => {
  //                     alert(error);
  //                 })
  //         }, 4000);

  //         return (() => {
  //             clearTimeout(timeoutId);
  //         })
  //     }
  // }, [sessionId, attempt])

  const handleOnChange = (record) => {
    const { id, issue } = record;
    const targetRecord = data.find((d) => d.id === id);
    targetRecord.isLoading = true;
    setData([...data]);

    const payload = {
      id: targetRecord.id.toString(),
      issue_value: Number(issue).toString(),
    };
    fetch(`https://botanalytics.intentico.ai/backend/update_issue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result && result.message === "Issue column updated successfully") {
          targetRecord.issue = issue;
          setData([...data]);
        }
      })
      .catch((error) => {})
      .finally(() => {
        targetRecord.isLoading = false;
        setData([...data]);
      });
  };

  const handleOnAddNewEntity = (id, label, newValue) => {
    const targetRecord = data.find((d) => d.id === id);
    if (targetRecord) {
      targetRecord.isLoading = true;
      setData([...data]);
      const payload = {
        id: targetRecord.id,
        entity: { ...targetRecord.entity, [label]: [newValue, 1] },
        entity_issue: 1,
      };
      fetch("https://botanalytics.intentico.ai/backend/update_issue", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((result) => {
          targetRecord.entity[label] = newValue;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          targetRecord.isLoading = false;
          setData([...data]);
        });
      setData([...data]);
    }
  };

  const handleOnRemoveEntity = (id, label) => {
    const targetRecord = data.find((d) => d.id === id);
    if (targetRecord) {
      targetRecord.isLoading = true;
      setData([...data]);
      const payload = {
        id: targetRecord.id,
        entity: { ...targetRecord.entity, [label]: null },
        entity_issue: 1,
      };
      fetch("https://botanalytics.intentico.ai/backend/update_issue", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((result) => {
          targetRecord.entity[label] = null;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          targetRecord.isLoading = false;
          setData([...data]);
        });
      setData([...data]);
    }
  };

  return (
    <Box p={2}>
      <Stack gap={2}>
        <Typography variant="h4" color={"primary"}>
          Intent Labeler
        </Typography>
        {loading ? (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Chip
              label="fetching data"
              icon={
                <CircularProgress
                  style={{ margin: "2px 10px", width: "18px", height: "18px" }}
                />
              }
            />
          </Stack>
        ) : (
          <LabelerTable
            data={data}
            onChange={handleOnChange}
            addNewEntity={handleOnAddNewEntity}
            removeEntity={handleOnRemoveEntity}
          />
        )}
      </Stack>
    </Box>
  );
};

export default Labeler;
