import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import FilterLabel from "../../../custom/FilterLabel";
import EntityChip from "./EntityChip";

const columns = [
  { id: "line", caption: "Line", width: 300 },
  { id: "entities", caption: "Entities", width: 480 },
  { id: "intent", caption: "Intent", hasFilter: true, width: 200 },
  { id: "language", caption: "Language", hasFilter: true, width: 20 },
  { id: "count", caption: "Count", width: 100 },
  { id: "issue", caption: "Intent Issue", width: 160 },
];

const StyleTableHead = styled(TableHead)(({}) => {
  return {
    color: "rgb(99, 115, 129)",
    fontWeight: "600",
    fontSize: "18px",
    backgroundColor: "rgb(244, 246, 248)",
  };
});

const StyledTableRow = styled(TableRow)(({}) => {
  return {
    transition: "all 400ms ease-in-out",
    "&[disabled]": {
      userSelect: "none",
      pointerEvents: "none",
      opacity: 0.26,
    },
  };
});

const StyleTableCell = styled(TableCell)(({}) => {
  return {
    borderBottom: "none",
  };
});

const LabelerTable = ({ data, onChange, addNewEntity, removeEntity }) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filters, setFilters] = React.useState({});

  const handleOnPageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(0);
  };

  const handleOnFilterChange = (event, field) => {
    filters[field] = event.target.value;
    setFilters({ ...filters });
  };

  let filteredData = data;

  const validFilters = Object.entries(filters).filter((entry) => entry[1]);
  if (validFilters.length > 0) {
    validFilters.forEach((filter) => {
      filteredData = filteredData.filter(
        (record) => record[filter[0]] === filter[1]
      );
    });
  }

  const PAGES = Math.ceil(filteredData.length / pageSize);
  const pageTicker = currentPage * pageSize;
  const pageData = filteredData.slice(pageTicker, pageTicker + pageSize);

  return (
    <Stack gap={2}>
      <Table size="small">
        <StyleTableHead>
          <TableRow>
            {columns.map((col) => {
              if (!col.hasFilter)
                return (
                  <StyleTableCell width={col.width} key={col.id}>
                    {col.caption}
                  </StyleTableCell>
                );

              return (
                <StyleTableCell key={col.id}>
                  <Stack direction="row" gap={1} alignItems="center">
                    {col.caption}
                    <FilterLabel
                      size="small"
                      select
                      value={filters[col.id]}
                      onChange={(event) => handleOnFilterChange(event, col.id)}
                    >
                      <MenuItem default={`default_${col.id}`}>All</MenuItem>
                      {Array.from(new Set(data.map((d) => d[col.id])))
                        .filter((x) => x)
                        .map((x) => {
                          return (
                            <MenuItem value={x} key={x}>
                              {x}
                            </MenuItem>
                          );
                        })}
                    </FilterLabel>
                  </Stack>
                </StyleTableCell>
              );
            })}
          </TableRow>
        </StyleTableHead>
        <TableBody>
          {pageData.map((record, index) => {
            return (
              <StyledTableRow
                disabled={Boolean(record.isLoading)}
                key={`line_record_${index}`}
              >
                <TableCell>{record.line}</TableCell>
                <TableCell>
                  <Stack direction="row" gap={0.5} flexWrap={"wrap"}>
                    {Object.entries(record.entity)
                      .sort((x) => (x[1] ? -1 : 1))
                      .map((value) => {
                        return (
                          <EntityChip
                            entity={value}
                            addNewEntity={(...args) =>
                              addNewEntity(record.id, ...args)
                            }
                            removeEntity={(...args) =>
                              removeEntity(record.id, ...args)
                            }
                          />
                        );
                      })}
                  </Stack>
                </TableCell>
                <TableCell>{record.intent}</TableCell>
                <TableCell>{record.language}</TableCell>
                <TableCell>{record.count}</TableCell>
                {/* <TableCell>{record.entities}</TableCell>
                                    <TableCell>{record.session_id}</TableCell> */}
                <TableCell>
                  <FormGroup>
                    <Stack direction="row" gap={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size={"small"}
                            value={"intent_issue"}
                            checked={Boolean(Number(record.issue))}
                            onChange={(event) =>
                              onChange({
                                id: record.id,
                                issue: event.target.checked,
                              })
                            }
                          />
                        }
                        label={`Intent Issue`}
                      />
                    </Stack>
                  </FormGroup>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <Stack
        alignItems={"center"}
        justifyContent={"space-between"}
        direction={"row"}
      >
        <Box>
          <Stack
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            direction={"row"}
          >
            <Typography>Records Per Page</Typography>
            <Select
              size="small"
              onChange={handleOnPageSizeChange}
              value={pageSize}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              disabled={Boolean(currentPage === 0)}
              onClick={() => setCurrentPage(0)}
            >
              First
            </Button>
            <Button
              variant="outlined"
              disabled={Boolean(currentPage === 0)}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              disabled={Boolean(currentPage === PAGES - 1)}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </Button>
            <Button
              variant="outlined"
              disabled={Boolean(currentPage === PAGES - 1)}
              onClick={() => setCurrentPage(PAGES - 1)}
            >
              Last
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default LabelerTable;
