import { CircularProgress } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { login, logout } from '../components/redux/app.slice';
import Session from '../components/services/Session.service';
import Storage from '../components/utils/session.util';

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

const InitPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const token = Storage.token;
  const user = Storage.user;
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (token) {
      const session = new Session();
      session.getPageStatus()
        .then(response => {
          if (response && response.status) {
            const {
              config,
              default_page,
              page_name,
              show_live_option,
              show_mobile_option
            } = response.data;

            const setting = {
              config,
              default_page,
              page_name,
              show_live_option,
              show_mobile_option
            };
            dispatch(login({token, setting}));
          } else {
            dispatch(logout());
          }
        }).finally(() => {
          setIsLoading(false);
        })
    } else {
      setIsLoading(false)
    }
  }, []);

  if (isLoading) {
    return <LoadingWrapper>
      <CircularProgress size="16px" color="primary"></CircularProgress>
    </LoadingWrapper>
  }

  return (<Outlet />);

};

export default InitPage;
