import { styled } from '@mui/material';
import TextField from './TextField';

const FilterLabel = styled(TextField)((props)=>{
    
    return ({
        '& .MuiInputBase-root': {
            '& .MuiSelect-select': {
                color: '#000000de',
                fontSize: '16px',
                fontWeidht: 500
            },
            '& .MuiOutlinedInput-input': {
                padding: '8.5px 32px 8.5px 0px',
            },
            '&.Mui-focused': {
                borderWidth: '0px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `transparent`,
            },
            '& .MuiInputBase-input': {
               '&:hover.MuiFilledInput-root::after': {
                    borderWidth: '0px',
                }
            },
        },
        '& .MuiInputBase-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `transparent`,
            },
        },
        '& .MuiInputBase-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `transparent`,
            },
        }
    })
});

export default FilterLabel;