import React from "react";
import 'rsuite/DateRangePicker/styles/index.css';
import { DateRangePicker } from 'rsuite';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Stack, ToggleButton, Grid, ToggleButtonGroup, Typography, styled } from "@mui/material"
import { MonitorSmartphone, Monitor, Smartphone } from 'lucide-react';
import Summary from "./Summary";
import Button from "../../../../custom/Button";
import Session from "../../../../services/Session.service";
import Charts from "../../../../services/Charts.service";
import moment from "moment";

const displayStyleCaption = {
    display: {
        xs: "none",
        sm: "initial"
    }
}
const displayStyleIcon = {
    height: '18px',
    display: {
        xs: "initial",
        sm: "none"
    }
}



const Reports = ({currentPage}) => {
    const [dateRange, setDateRange] = React.useState(null);

    const [config, setConfig] = React.useState(
        {
            date_range: [],
            predefined_ranges: [
                { name: "Feb custom", val: [moment("2017-02-08"), moment('2017-02-09')] },
                { name: "This Month", val: [moment().startOf('month'), moment()] },
                { name: "This Week", val: [moment().startOf('week'), moment()] },
                { name: "Last 30 days", val: [moment().subtract(29, 'days'), moment()] },
                { name: "Last 2 weeks", val: [moment().subtract(7, 'days'), moment().subtract(1, 'days')] },
                { name: "Custom", val: "custom" },
            ],
            maxRange: [moment().startOf('year').format('01/01/2019'), moment().add(1, 'days')],
            customDateRange: null,
            dataLoading: false,
            currentRangeTmp: null,
            currentRange: null,
            customModalOpen: false,
            count: 0,
            currentPage: null,
            config: { pages: [] },
            data_store: {},
            navData: null,
            clickStatus: 0,
            filterdata: null
        }
    )
    const [pageConfig, setPageConfig] = React.useState(null); 
    const [graphData, setGraphData] = React.useState([]);
    const [deviceFilter, setDeviceFilter] = React.useState("all");
    const location = useLocation();
    const handleOnChangeDevice = (event, newDeviceFilter,) => {
        if (newDeviceFilter) {
            setDeviceFilter(newDeviceFilter);
        }
    }

    React.useEffect(() => {
        if(currentPage){
            if(!dateRange){
                const [startDate, endDate] = config.predefined_ranges[4].val; 
                setDateRange([new Date(startDate.toString()), new Date(endDate.toString())]);
            }
            const session = new Session();
            session.getPageStatus(currentPage)
                .then(response => {
                    if (response && response.status) {
                        setPageConfig([...response.data.config]);
                    }
                })
        }
    }, [currentPage]);

    React.useEffect(() => {
        if(pageConfig){
            getReports();
        }
    }, [pageConfig, dateRange])

    const getReports = () => {
        pageConfig.forEach(conf => {
            if (conf.id) {
                conf.paraNames = ["from_date", "to_date"];
                conf.params = dateRange;
            }
        })
        const charts = new Charts();
        charts.getDashboardReports(pageConfig)
            .then(response => {
                if (response) {
                    for (let prop in response) {
                        
                        if(response[prop].data){
                            pageConfig[prop].graphData = response[prop].data;
                            let chartData = pageConfig[prop].charts;

                            if(typeof chartData === "string")
                                chartData = JSON.parse(chartData);

                            pageConfig[prop].charts = chartData;
                        }
                    }
                    setGraphData(pageConfig.filter(x => x.id))
                }
            })
            .finally();
    }

    const handleOnDateChange = (range) => {
        setDateRange([...range]);
    }

    return (
        <Box>
            <Stack gap={4}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="h4"
                                color={"primary"}
                            >
                                Intentico Botlytics
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Stack direction="row" justifyContent={"flex-end"} gap={2}>

                                <Box sx={{ minWidth: '220px' }}>
                                    <DateRangePicker onChange={handleOnDateChange} value={dateRange} />
                                </Box>

                                <Button to="?page=live" component={NavLink}>Live</Button>

                                <ToggleButtonGroup
                                    value={deviceFilter}
                                    exclusive
                                    color="primary"
                                    size="small"
                                    onChange={handleOnChangeDevice}
                                    aria-label="device filter"
                                >
                                    <ToggleButton size="small" value="all" aria-label="all devices">
                                        <Typography variant="body2" sx={displayStyleCaption}>
                                            All
                                        </Typography>
                                        <Box sx={displayStyleIcon}>
                                            <MonitorSmartphone size={18} />
                                        </Box>
                                    </ToggleButton>
                                    <ToggleButton value="desktop" aria-label="desktop devices">
                                        <Typography variant="body2" sx={displayStyleCaption}>
                                            Desktop
                                        </Typography>
                                        <Box sx={displayStyleIcon}>
                                            <Monitor size={18} />
                                        </Box>
                                    </ToggleButton>
                                    <ToggleButton value="mobile" aria-label="mobile devices">
                                        <Typography variant="body2" sx={displayStyleCaption}>
                                            Mobile
                                        </Typography>
                                        <Box sx={displayStyleIcon}>
                                            <Smartphone size={18} />
                                        </Box>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                <Summary graphData={graphData} />
            </Stack>

        </Box>
    )
}

export default Reports;