import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import SplashLoader from '../../components/controls/SplashLoader';
import React from 'react';


const PrivateRoute = (props) => {
    const {isLoading, token} = useSelector(state => state.app);
    const location = useLocation();
    if(isLoading && !token) {
        return (<SplashLoader />);
    }
    
    if(!token)
        return <Navigate to='/session/login' replace state={{from: location}} />;
    return <React.Fragment>{props.children}</React.Fragment>;
}

const ProtectedRoute = (props) => {
    const {token} = useSelector(state => state.app);
    const location = useLocation();
    if(token){
        if(location.state && location.state.from){
            return <Navigate to={location.state.from} replace />;
        }
        else{
            return <Navigate to='/_' replace/>;
        }
    }
    
    return <React.Fragment>{props.children}</React.Fragment>;
}

export { PrivateRoute, ProtectedRoute };
