import { styled } from '@mui/material';
import MuiButton from '@mui/material/Button';

const StyledButton = styled(MuiButton)(() => {
    return ({
        borderRadius: '4px'
    })
})

const Button = (props) => {
    return (
        <StyledButton
            {...props}
            disableRipple
            disableElevation
        >{props.children}</StyledButton>
    )
}

export default Button;