import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import NotificationProvider from '../../context/toast';

const Wrapper = styled(Box)(({theme}) => {
    return ({
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    })
}) 

const Session = () => {
  return(
      <NotificationProvider>
        <Wrapper>
            <Outlet />
        </Wrapper>
      </NotificationProvider>
  );
};

export default Session;