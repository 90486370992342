import { Stack, Typography } from "@mui/material";
import Chart from "./charts";
import Wrapper from "../../../../custom/GraphWrapper";


const InfoBox = (props) => {
    let { charts, graphData } = props;
    const { title, subtitle } = charts;
    const count = graphData[0].count;
    return (
        <Wrapper className="bg" i={props.i}>
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Typography variant="h4">{title}</Typography>
                    <Typography variant="h2">{count}</Typography>
                    <Typography>{subtitle}</Typography>
                </Stack>
        </Wrapper>
    )
}

const SummaryCard = (props) => {
    let { charts, graphData } = props;
    if (charts.type === "infobox") {
        return (<InfoBox i={props.i} graphData={graphData} charts={charts} />)
    }
    
    const options = {
        xaxis: {
            categories: graphData.map(x => Object.values(x)[1]) //[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
    }
    const series = [
        {
            name: charts.y[0].dataKey,
            data: graphData.map(x => Object.values(x)[1])
        }
    ]
    return (
        <Wrapper>
            <Stack>
                <Typography>{charts.title}</Typography>
                <Chart type={charts.type} options={options} series={series} />
            </Stack>
        </Wrapper>
    )
}

export default SummaryCard;