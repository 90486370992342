import { styled, Box, } from '@mui/material';

const gradients = [
    'linear-gradient(to right, #0acffe 0%, #495aff 100%)',
    'linear-gradient(60deg, #96deda 0%, #50c9c3 100%)',
    'linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%)',
    'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
    'linear-gradient(to top, #9be15d 0%, #00e3ae 100%)',
]


const Wrapper = styled(Box)(({ theme, i }) => {
    return {
        padding: '16px',
        borderRadius: '8px',
        background: theme.palette.grey[200],
        '&.bg': {
            alignItems: 'center',
            padding: '32px',
            backgroundImage: gradients[i],
            color: '#fff',
            '& h2': {
                fontSize: '56px !important',
            }
        }
    };
})

export default Wrapper;