import { Box, Grid, Stack, Typography, styled } from "@mui/material"

import BannerBackground from '../../../assets/banners/banner_background.svg';
import BannerCharacter from '../../../assets/banners/report_slide_women.svg'
import Button from "../../../custom/Button";

const BannerContainer = styled('div')(() => {
    return ({
        borderRadius: '16px',
        padding: '32px',
        color: '#00a76f',
    })
})

const WelcomeBanner = styled(BannerContainer)(({theme}) => {
    return ({
        background: '#dcf7ec',
    })
})

const ProductBanner = styled(BannerContainer)(() => {
    return ({
        backgroundImage: `url('https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_1.jpg')`,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '366px',
        width: '100%',
    })
})

const BannerImage = styled('div')(() => {
    return ({
        backgroundImage: `url(${BannerBackground}), url(${BannerCharacter})`,
        backgroundSize: 'auto 96%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '300px',
        width: '100%',
    })
})

const Banner = () => {
    return (
        <Box>
            <Grid container alignItems={"stretch"} spacing={4}>
                <Grid item xs={12} sm={8}>
                    <WelcomeBanner>
                        <Grid container spacing={2} alignItems={"center"} >
                            <Grid item sm={6}>
                                <Stack gap={3} alignItems={"flex-start"}>
                                    <Stack>
                                        <Typography variant="h3">
                                            Welcome back 👋
                                        </Typography>
                                        <Typography variant="h3">
                                            Intentico Botlytics
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <Typography variant="subtitle1">
                                            If you are going to use passage of Lorem Ipsum,
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            you need to be sure there isn't anything
                                    </Typography>
                                    </Stack>

                                    <Button variant="contained" >Go Now</Button>
                                </Stack>
                            </Grid>
                            <Grid item sm={6}>
                                <BannerImage />
                            </Grid>
                        </Grid>
                    </WelcomeBanner>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ProductBanner />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Banner;