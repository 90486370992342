import axios from 'axios';
import sessionStorage from '../../utils/session.util';
import Storage from '../../utils/session.util';

let baseURL = 'https://botlytics.intentico.ai/botlyticsbck_new';

const AccessPoint = axios.create({
  timeout: 40000,
  mode: 'cors',
  baseURL,
  withCredentials: true
});

AccessPoint.interceptors.request.use(
  (config) => {
    const token = sessionStorage.token;
    if (token && !config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
AccessPoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.code === 'ECONNABORTED') {
      return Promise.reject({
        message:
          'This request takes too long to process, it is timed out by the server. Please try again later.',
        status: false,
        statusCode: -1
      });
    }
    if (error.code === 'ERR_NETWORK') {
      return Promise.reject({
        message: 'Could not connect to the server, please try again.',
        status: false,
        statusCode: -1
      });
    }
    if (error.response.status === 401 && !window.location.href.endsWith('/login')) {
      sessionStorage.removeStorage();
      window.location = '/';
      return;
    }

    if (error.response.status === 403  && !window.location.href.endsWith('/login')) {
      sessionStorage.removeStorage();
      window.location = '/';
      return;
    }

    const responseData = error.response.data;
    return Promise.resolve({
      error: responseData.error,
      type: responseData.type,
      message: responseData.msg,
      status: responseData.status,
      statusCode: error.response.status
    });
  }
);

export default AccessPoint;
