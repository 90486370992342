import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from '../components/redux';
import { defaultTheme } from '../configs/theme.config';

const Setup = (props) => {
  const theme = createTheme(defaultTheme);
  return(
    <ThemeProvider theme={ theme }>
      <Provider store={store}>
        <div>{props.children}</div>
      </Provider>
    </ThemeProvider>
  );
};

export default Setup;