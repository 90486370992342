import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import { Check, X } from 'lucide-react';
import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuid4 } from 'uuid';


const ToastContext = React.createContext();

const Wrapper = styled(Box)(({theme}) => {
    return ({
        position: 'fixed',
        top: '0px',
        right: '0px',
        padding: '8px',
        zIndex: 99999999,
    })
})

const ToastProvider = (props) => {
    
    const [toasts, setToasts] = React.useState([]);
    const open = (kind, title, subtitle) =>
        setToasts((currentToasts) => [
        ...currentToasts,
        { id: uuid4(), kind, title, subtitle },
        ]);
    const onClose = (id) =>
        setToasts((currentToasts) =>
        currentToasts.filter((toast) => toast.id !== id)
        );
    const contextValue = useMemo(() => ({ open }), []);

    return (
        <ToastContext.Provider value={contextValue}>
            {props.children}

            {createPortal(
                <Wrapper>
                    <Stack gap={.5}>
                    {
                        toasts.map(toast => <Toast onClose={onClose} key={toast.id} {...toast} />)
                    }
                    </Stack>
                </Wrapper>,
                document.body
            )}
        </ToastContext.Provider>
    )
}

const ToastWrapper = styled(Box)(({theme, borderColor, background}) => {

    return({
        border: `1px solid ${borderColor}`,
        background: background,
        padding: '12px 16px',
        color: '#FFFFFF',
        width: '320px',
        borderRadius: '12px',
        alignItems: 'flex-start',
        '& .toast-content': {
            flexGrow: 1,
            '& .subtitle': {
                fontWeight: 'bold',
                lineHeight: 'initial',
                marginBottom: '4px',
            },
            '& .body': {
                lineHeight: 'initial',
            }
        },
        '& .toast-emblem': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: 6,
            backgroundColor: '#00000041',
        },
        '&.disappearUpToast': {
            animation: 'disappearUpToast 200ms ease-in-out forwards'
        },
        '&.appearUpToast': {
            animation: 'appearUpToast 200ms ease-in-out forwards'
        },
    })
})

const Toast = ({id, kind, title, subtitle, onClose}) => {
    let _borderColor, _backgroundColor, _icon;

    switch (kind) {
        case "success":{
            _borderColor = '#43D590';
            _backgroundColor = 'linear-gradient(to right, #32BB71, #2A9D8F)';
            _icon = <Check size={'16px'} />;
            break;
        }
        case "error":{
            _borderColor = '#F0863A';
            _backgroundColor = 'linear-gradient(to right, #F6743E, #D42525)';
            _icon = <X size={'16px'} />;
            break;
        }
        case "info":{
            _borderColor = '#7BCFED';
            _backgroundColor = 'linear-gradient(to right, #2D82B2, #329ABB)';
            _icon = 'i'
            break;
        }
        case "warning":{
            _borderColor = '#FFDF8D';
            _backgroundColor = 'linear-gradient(to right, #F8B806, #FF8C04)';
            _icon = '!';
            break;
        }
    }
    React.useEffect(() => {
        const toastTimeoutId = setTimeout(() => {
            onClose(id);
        }, 3000);
        return (() => clearTimeout(toastTimeoutId))
    }, [])

    return(
        <ToastWrapper borderColor={_borderColor} background={_backgroundColor}>
            <Stack direction={"row"} gap={1.5} alignItems={"flex-start"}>
                <Box className="toast-icon">
                    <span className='toast-emblem'>
                        {_icon}
                    </span>
                </Box>
                <Box className="toast-content">
                    <Stack>
                        <Typography className="subtitle" variant="subtitle1">{title}</Typography>
                        <Typography className="body" variant="body2">{subtitle}</Typography>
                    </Stack>
                </Box>
                <Box className="toast-control">
                    <IconButton size='small' onClick={() => onClose(id)}>
                        <X size={"18px"} />
                    </IconButton>
                </Box>
            </Stack>
        </ToastWrapper>
    )
}

export default ToastProvider;
export { ToastContext };
