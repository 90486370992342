import { Grid, Stack, Typography } from "@mui/material";
import SummaryCard from "./SummaryCard";

const Summary = ({ graphData }) => {
    return (
        <Stack gap={4}>
            <Stack gap={2} direction={"column"}>
                <Typography variant="h6" color="primary">Summary</Typography>
                <Grid container spacing={2}>
                    {
                        graphData.filter(x => x.charts.type === "infobox").map((conf, i) => {
                            return (
                                <Grid key={conf.id} item sm={3} alignItems={"stretch"}>
                                    <SummaryCard {...conf} i={i} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Stack>
            <Stack gap={2}>
                <Typography variant="h6" color="primary">Graphs</Typography>
                <Grid container spacing={2}>
                    {
                        graphData.filter(x => x.charts.type !== "infobox").map(conf => {
                            return (
                                <Grid  key={conf.id} item sm={6} alignItems={"stretch"}>
                                    <SummaryCard {...conf} />
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </Stack>
        </Stack>
    )
}

export default Summary;