import { Box, Stack, Table, TableRow, TableHead, Typography, MenuItem, Button, Select, TableBody, TableCell, styled } from '@mui/material';
import React from 'react';
const columns = [
    { id: 'campaign', caption: 'Campaign', width: 160 },
    { id: 'bankName', caption: 'Bank Name', width: 100 },
    { id: 'language', caption: 'Language', width: 80 },
    { id: 'line', caption: 'Line', width: 120 },
    { id: 'response', caption: 'Response', width: 300 },
    { id: 'intent', caption: 'Intent', width: 60 },
    { id: 'entity', caption: 'Entity', width: 60 },
    { id: 'date', caption: 'Date', width: 60 },
    { id: 'sessionId', caption: 'Session Id', width: 80 }
];

const StyleTableHead = styled(TableHead)(({ }) => {
    return (
        {
            color: 'rgb(99, 115, 129)',
            fontWeight: '600',
            fontSize: '18px',
            backgroundColor: 'rgb(244, 246, 248)',
        }
    )
})

const StyleTableCell = styled(TableCell)(({ }) => {
    return (
        {
            borderBottom: 'none',
        }
    )
})

const ASRTable = ({ data, onChange }) => {

    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const PAGES = Math.ceil(data.length / pageSize)

    const pageTicker = currentPage * pageSize;
    const pageData = data.slice(pageTicker, pageTicker + pageSize);

    const handleOnPageSizeChange = (e) => {
        setPageSize(e.target.value);
        setCurrentPage(0);
    }

    return (
        <Stack gap={2}>
            <Table size="small">
                <StyleTableHead>
                    <TableRow>
                        {
                            columns.map(col => {
                                return (<StyleTableCell width={col.width} key={col.id}>{col.caption}</StyleTableCell>)
                            })
                        }
                    </TableRow>
                </StyleTableHead>
                <TableBody>
                    {
                        pageData.map((record, index) => {
                            return (
                                <TableRow key={`line_record_${index}`}>
                                    <TableCell>{record.Campaign}</TableCell>
                                    <TableCell>{record.bank_name}</TableCell>
                                    <TableCell>{record.language}</TableCell>
                                    <TableCell>{record.line}</TableCell>
                                    <TableCell>{record.response}</TableCell>
                                    <TableCell>{record.intent}</TableCell>
                                    <TableCell>{record.entity}</TableCell>
                                    <TableCell>{record.date}</TableCell>
                                    <TableCell>{record.session_id}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                <Box>
                    <Stack gap={1} alignItems={"center"} justifyContent={"center"} direction={"row"}>
                        <Typography>Records Per Page</Typography>
                        <Select
                            size="small"
                            onChange={handleOnPageSizeChange}
                            value={pageSize}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" gap={1}>
                        <Button variant="outlined" disabled={Boolean(currentPage === 0)} onClick={() => setCurrentPage(0)}>First</Button>
                        <Button variant="outlined" disabled={Boolean(currentPage === 0)} onClick={() => setCurrentPage(currentPage - 1)}>Previous</Button>
                        <Button variant="outlined" disabled={Boolean(currentPage === (PAGES - 1))} onClick={() => setCurrentPage(currentPage + 1)}>Next</Button>
                        <Button variant="outlined" disabled={Boolean(currentPage === (PAGES - 1))} onClick={() => setCurrentPage(PAGES - 1)}>Last</Button>
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    )
}

export default ASRTable;