import { Box, styled } from '@mui/material';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import NotificationProvider from '../../context/toast';
import AppBar from './segments/Appbar';
const Wrapper = styled(Box)(({theme}) => {
    return ({
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: "column"
    })
}) 

const App = () => {
    return (
            <NotificationProvider>
                <Wrapper>
                    <Box>
                        <AppBar />
                    </Box>
                    <Box sx={{ padding: '10px', width: '100%'}}><Outlet /></Box>
                    <Toaster />
                </Wrapper>
            </NotificationProvider>
    );
}

export default App;