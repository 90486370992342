import React, { Component } from "react";
import AppexChart from "react-apexcharts";
import { Typography } from '@mui/material';

const Chart = ({ type, options, series }) => {

    if (!options || !series) {
        return (<Typography>No Data Found</Typography>)
    }
    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <AppexChart
                        options={{
                            chart: {
                                
                            },
                            ...options
                        }}
                        series={series}
                        type={type}
                    />
                </div>
            </div>
        </div>
    );
}

export default Chart;