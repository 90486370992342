import { Navigate, Outlet } from 'react-router-dom';
import InitPage from "../boot/InitPage";
import { PrivateRoute, ProtectedRoute } from "../boot/routes/Routes";
import PageNotFound from '../components/pages/NotFound/PageNotFound';
import App from '../components/pages/app';
import Session from '../components/pages/session';
import Login from '../components/pages/session/Login';
import Dashboard from '../components/pages/app/dashboard';
import QueryGraph from '../components/pages/app/report_query';
import Labeler from '../components/pages/app/labeler';
import ASR from '../components/pages/app/asr';

const routes = [
  {
    path: '',
    element: <InitPage />,
    children: [
      {
        path: '',
        element: <Navigate replace to='/session/login' />
      },
      {
        path: 'session',
        element: <ProtectedRoute><Session /></ProtectedRoute>,
        children: [
          {
            path: 'login',
            element: <Login />
          },
          
        ]
      },
      {
        path: '_',
        element: <PrivateRoute><Outlet /></PrivateRoute>,
        children: [
          {
            path: '',
            element: <App />,
            children: [
              {
                path: '',
                element: <Navigate replace to='dashboard' />
              },
              {
                path: 'dashboard',
                element: <Dashboard />
              },
              {
                path: 'labeler',
                element: <Labeler />
              },
              {
                path: 'asr',
                element: <ASR />
              },
              {
                path: 'query',
                element: <QueryGraph />
              },
            ]
          },
          
        ]
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default routes;