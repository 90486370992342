import AccessPoint from './AccessPoint';
import defaultConfig from './DefaultConfig';

class HttpClient {
  constructor(accessPoint) {
    if (!accessPoint) this.accessPoint = AccessPoint;
    else this.accessPoint = accessPoint;
  }
  async get(url, config = defaultConfig) {
    const response = await this.accessPoint.get(url, config);
    return response.data;
  }
  async post(url, body, config = defaultConfig) {
    const response = await this.accessPoint.post(url, body, config);
    return response.data;
  }
  async put(url, body, config = defaultConfig) {
    const response = await this.accessPoint.put(url, body, config);
    return response.data;
  }
  async delete(url, body, config = defaultConfig) {
    const response = await this.accessPoint.delete(url, body, config);
    return response.data;
  }
  async upload(url, body, config = defaultConfig) {
    config.headers['Content-Type'] = 'multipart/form-data';
    const response = await this.accessPoint.post(url, body, config);
    return response.data;
  }
}

export default HttpClient;
