import { Stack, Typography, Box, Grid } from '@mui/material';
import React from 'react';
import TextField from '../../../custom/TextField';
import Button from '../../../custom/Button';
import Wrapper from '../../../custom/GraphWrapper';
import SummaryCard from '../dashboard/reports/SummaryCard';
import SplashLoader from '../../../controls/SplashLoader';

const QueryForm = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [queryId, setQueryId] = React.useState(null);
    const [query, setQuery] = React.useState('get me the count of answered calls');
    const [result, setResult] = React.useState(null);

    const handleOnClick = () => {
        if (query) {
            setIsLoading(true);

            fetch(`http://https://botanalytics.intentico.ai/backend/:8071/query?text=${query}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 9229ud7ivimfsotcboatboej0',
                },
                withCredentials: true
            })
                .then(response => response.json())
                .then(result => {
                    setQueryId(result.id);
                })
                .finally(() => {
                })
        } else {
            alert("Enter query");
        }
    }

    React.useEffect(() => {
        let timeoutId;
        if (queryId) {
            timeoutId = setTimeout(() => {
                fetch(`http://https://botanalytics.intentico.ai/backend/:8071/getresult?session_id=${queryId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer 9229ud7ivimfsotcboatboej0',
                    },
                    mode: 'cors',
                    withCredentials: true
                })
                    .then(response => response.json())
                    .then(result => {
                        if (result && result.rows) {
                            setResult([...result.rows.map(x => ({
                                graphData: [{ count: x.count }],
                                charts: {
                                    title: 'title',
                                    subtitle: 'subtitle',
                                    type: 'infobox'
                                }
                            }))]);
                        }
                    })
                    .catch(error => {

                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }, 2000);
        }

        return (() => {
            if (timeoutId)
                clearTimeout(timeoutId);
        })
    }, [queryId]);

    return (
        <Stack gap={2}>
            <Stack direction="row" gap={2}>
                <Box display={'flex'} flexGrow={1}>
                    <TextField
                        placeholder="Enter your query"
                        size="small"
                        fullWidth
                        disabled={isLoading}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                </Box>
                <Button disabled={isLoading} onClick={handleOnClick} variant="contained" >Send</Button>
            </Stack>
            <Box>
                {
                    isLoading ?
                        <SplashLoader />
                        :
                        <Box>
                            {
                                result &&
                                <Stack gap={2}>
                                    <Typography>Result</Typography>
                                    <Grid container spacing={2}>

                                        {
                                            result.map((item, i) => (
                                                <Grid key={`query_result_${i}`} item sm={3} alignItems={"stretch"}>
                                                    <SummaryCard {...item} i={i} />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Stack>
                            }
                        </Box>
                }
            </Box>
        </Stack>
    )
}

export default QueryForm;