import { Select, Box, Chip, Divider, InputAdornment, CircularProgress, MenuItem, Stack, IconButton, Typography, styled } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import ASRTable from './Table';
import React from 'react';
import TextField from '../../../custom/TextField';

const StyledSearchBox = styled(TextField)(({theme}) => {
    return({
        '&': {
            width: '420px',
            '& .MuiInputBase-root': {
                paddingLeft: '0px',
            },
            '& input': {
                marginLeft: '14px',
            }
        }
    })
});

const StyledSelect = styled(Select)(({theme}) => {
    return({
        minWidth: '140px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        }
    })
});

const filters = [
    {
        value: 'Campaign', label: 'Campaign',
    },
    {
        value: 'bank_name', label: 'Bank Name',
    },
    {
        value: 'language', label: 'Language',
    }
]

const ASR = () => {
    const [data, setData] = React.useState([]);
    const [sessionId, setSessionId] = React.useState(null);
    const [attempt, setAttempt] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [filterBy, setFilterBy] = React.useState('');
    const [filterValue, setFilterValue] = React.useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setLoading(true);
        fetch('https://botanalytics.intentico.ai/backend/get_asr_data')
            .then(response => response.json())
            .then(result => {
                if (result && result.id) {
                    setSessionId(result.id);
                }
            })
    }, []);

    React.useEffect(() => {
        if (sessionId) {
            const timeoutId = setTimeout(() => {
                fetch(`https://botanalytics.intentico.ai/backend/getresult?session_id=${sessionId}`)
                    .then(response => response.json())
                    .then(result => {
                        if (result && result.detail === "Query execution failed") {
                            if (attempt > 10) {
                                setLoading(false)
                            } else {
                                setAttempt(prev => prev + 1);
                            }
                        } else if (result && result.rows) {
                            setLoading(false);
                            const rows = result.rows;
                            if (rows && Array.isArray(rows) && rows.length > 0) {
                                setData(rows);
                            }
                        }
                    })
                    .catch(error => {
                        alert(error);
                    })
            }, 4000);

            return (() => {
                clearTimeout(timeoutId);
            })
        }
    }, [sessionId, attempt])

    const clearFilter = () => {
        setFilterBy('');
        setFilterValue('')
    }

    const onSelection = (filter) => () => {
        const filterBy = filters.find(f => f.value === filter);
        
        if (filterBy) {
            setFilterBy(filterBy);
        }
        setAnchorEl(null);
    }

    let filteredData = data;
    if (filterBy && filterValue) {
        filteredData = filteredData.filter(f => f[filterBy].toLowerCase().includes(filterValue.toLowerCase()));
    }
    return (
        <Box p={2}>
            <Stack gap={2}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography
                        variant="h4"
                        color={"primary"}
                    >
                        ASR
                    </Typography>
                    <StyledSearchBox
                        size="small"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        InputProps={{
                            startAdornment: (<InputAdornment>
                                <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"}>
                                    <StyledSelect
                                        size="small"
                                        sx={{
                                            border: "none",
                                        }}
                                        value={filterBy}
                                        placeholder='Filter by'
                                        onChange={(e) => setFilterBy(e.target.value)}
                                    >
                                        <MenuItem value={''}>Filter by</MenuItem>
                                        {
                                            filters.map(f => {
                                                return (
                                                    <MenuItem value={f.value}>{f.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </StyledSelect>
                                    <Divider orientation='vertical' flexItem />
                                </Stack>
                            </InputAdornment>),
                            endAdornment: filterBy || filterValue ? <IconButton size="small" onClick={clearFilter}><Close /></IconButton> : <Search />
                        }}
                    />
                </Stack>
                {
                    loading ?
                        <Stack alignItems={"center"} justifyContent={"center"}>
                            <Chip
                                label="fetching data"
                                icon={<CircularProgress style={{ margin: '2px 10px', width: '18px', height: '18px' }} />}
                            />
                        </Stack>
                        :
                        <ASRTable data={filteredData} />
                }
            </Stack>
        </Box>
    )
}

export default ASR;