const baseTheme = {}

const defaultTheme = {
    palette: {
        background: {
          default: "#f9f9fc"
        },
        primary: {
            lighter: '#C8FAD6',
            light: '#5BE49B',
            main: '#00A76F',
            dark: '#007867',
            darker: '#004B50',
        },
        secondary: {
            lighter: '#EFD6FF',
            light: '#C684FF',
            main: '#8E33FF',
            dark: '#5119B7',
            darker: '#27097A',
        },
        info: {
            lighter: '#CAFDF5',
            light: '#61F3F3',
            main: '#00B8D9',
            dark: '#006C9C',
            darker: '#003768',
        },
        success: {
            lighter: '#D3FCD2',
            light: '#77ED8B',
            main: '#22C55E',
            dark: '#118D57',
            darker: '#065E49',
        },
        warning: {
            lighter: '#FFF5CC',
            light: '#FFD666',
            main: '#FFAB00',
            dark: '#B76E00',
            darker: '#7A4100',
        },
        error: {
            lighter: '#FFE9D5',
            light: '#FFAC82',
            main: '#FF5630',
            dark: '#B71D18',
            darker: '#7A0916',
        },
        grey: {
            100: '#F9FAFB',
            200: '#F4F6F8',
            300: '#DFE3E8',
            400: '#C4CDD5',
            500: '#919EAB',
            600: '#637381',
            700: '#454F5B',
            800: '#212B36',
            900: '#161C24',
        },
    },
    typography: {
        fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        h1: {
            fontSize: "58px",
            lineHeight: "72.5px",
            weight:800,
            letterSpacing: "0px"
        },
        h2: {
            fontSize: "44px",
            lineHeight: "58.666666666666664px",
            weight:800,
            letterSpacing: "0px"
        },
        h3: {
            fontSize: "30px",
            lineHeight: "45px",
            weight:700,
            letterSpacing: "0px"
        },
        h4: {
            fontSize: "24px",
            lineHeight: "36px",
            weight:700,
            letterSpacing: "0px"
        },
        h5: {
            fontSize: "20px",
            lineHeight: "30px",
            weight:700,
            letterSpacing: "0px"
        },
        h6: {
            fontSize: "18px",
            lineHeight: "28px",
            weight:700,
            letterSpacing: "0px"
        },
        subtitle1: {
            fontSize: "16px",
            lineHeight: "24px",
            weight:600,
            letterSpacing: "0px"
        },
        subtitle2: {
            fontSize: "14px",
            lineHeight: "22px",
            weight:600,
            letterSpacing: "0px"
        },
        body1: { 
            fontSize: "16px",
            lineHeight: "24px",
            weight:400,
            letterSpacing: "0px"
        },
        body2: { 
            fontSize: "14px",
            lineHeight: "22px",
            weight:400,
            letterSpacing: "0px"
        },
        caption: {
            fontSize: "12px",
            lineHeight: "18px",
            weight:400,
            letterSpacing: "0px"
        },
        overline: {
            fontSize: "12px",
            lineHeight: "18px",
            weight:700,
            letterSpacing: "0px"
        },
        button: {
            fontSize: "14px",
            lineHeight: '24px',
            weight:700,
            letterSpacing: "0px"
        }
    }
}
const lightTheme = {}
const darkTheme = {}

export {
    baseTheme,
    defaultTheme,
    lightTheme,
    darkTheme,
}