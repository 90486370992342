import { styled } from '@mui/material';
import MuiLink from '@mui/material/Link';

const Link = styled(MuiLink)(({theme, color}) => {
    const _color = theme.palette[color]?.main ? theme.palette[color].main : '#2c2c2c';
    return ({
        color: _color,
        textDecoration: 'none',
        fontSize: 'inherit',
        fontWeight: 500,

        padding: '4px 0px',
        cursor: 'pointer',
        '&:hover':{
            color: theme.palette.primary.dark
        }
    })
})

export default Link;