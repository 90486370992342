import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from '../../configs/route.config';


const RouteLayout = () => {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default RouteLayout;