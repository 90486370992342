function isValidUsername(username) {
    if (username.length === 0)
      return {
        isValid: false,
        errorMessage: 'Username cant be empty. You must provide correct username to login'
      };
  
    return { isValid: true, errorMessage: '' };
  }
  
  function isValidPassword(password) {
    if (password.length === 0) return { isValid: false, errorMessage: 'Password cant be empty' };
  
    return { isValid: true, errorMessage: '' };
  }
  
  function isValidProjectName(projectName) {
    if (projectName.length === 0)
      return { isValid: false, errorMessage: 'Project name cant be empty' };
    else if (projectName.length <= 3)
      return {
        isValid: false,
        errorMessage:
          'Project name is very short. Make you sure you have entered correct project name'
      };
  
    return { isValid: true, errorMessage: '' };
  }
  
  function isValidProjectDescription(projectDescription) {
    if (projectDescription.length === 0)
      return {
        isValid: false,
        errorMessage: 'Project description cant be empty'
      };
    else if (projectDescription.length <= 3)
      return {
        isValid: false,
        errorMessage: 'Project description is very short. Provide some information about the project.'
      };
  
    return { isValid: true, errorMessage: '' };
  }
  
  // const validateData = (kind, value) => {
  //   switch (kind) {
  //     case 'username':
  //       return isValidUsername(value);
  //     case 'password':
  //       return isValidPassword(value);
  //     case 'projectname':
  //       return isValidProjectName(value);
  //     case 'description':
  //       return isValidProjectDescription(value);
  //     default:
  //       return { isValid: true, errorMessage: '' };
  //   }
  // };
  
  function isValidEmailAddress(value) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(validRegex)) {
      const lastDotPosition = value.lastIndexOf('.');
      const atPosition = value.lastIndexOf('@');
      if (lastDotPosition > atPosition + 2) {
        return {
          isValid: true,
          errorMessage: ''
        };
      }
  
      return {
        isValid: false,
        errorMessage: 'Make sure that you have entered a valid email address'
      };
    } else
      return {
        isValid: false,
        errorMessage: 'Invalid email address'
      };
  }
  
  const validateData2 = (checks, value) => {
    if (!checks) {
      return { isValid: true, errorMessage: '' };
    }
  
    const { isRequired, ...rest } = checks;
    if (isRequired && value) {
      const checkList = Object.entries(rest);
      const errorMessage = [];
      checkList.forEach(([entryKey, entryValue]) => {
        let response = null;
        switch (entryKey) {
          case 'isRequired': {
            response = entryValue && entryValue !== Boolean(value) ? `This field is required` : null;
            break;
          }
          case 'type': {
            response =
              value && typeof value !== entryValue
                ? `Value must be a ${typeof entryValue}, not ${typeof value}`
                : null;
            break;
          }
          case 'min': {
            response =
              value?.trim() && value.toString().length < entryValue
                ? `Value must be at least ${entryValue} characters`
                : null;
            break;
          }
          case 'max': {
            response =
              value?.trim() && value.toString().length > entryValue
                ? `Value should be at greater than ${entryValue} characters`
                : null;
            break;
          }
          default: {
            break;
          }
        }
        if (response) {
          errorMessage.push(response);
        }
      });
  
      return {
        isValid: !errorMessage.length,
        errorMessage: errorMessage.join(', ')
      };
    } else if (isRequired && !value) {
      return {
        isValid: false,
        errorMessage: 'This field is required'
      };
    } else {
      return {
        isValid: true,
        errorMessage: ''
      };
    }
  };
  
  const validateFieldData = (checks, value) => {
    if (!checks) {
      return { isValid: true, errorMessage: '' };
    }
    
    const { isRequired, ...rest } = checks;
    if(isRequired && !value) {
      return {
        isValid: false,
        errorMessage: 'This field is required, please enter a value',
      };
    }
  
    if (value) {
      const checkList = Object.entries(rest);
      const errorMessage = [];
      checkList.forEach(([entryKey, entryValue]) => {
        let response = null;
        switch (entryKey) {
        case 'isRequired': {
          response = entryValue && entryValue !== Boolean(value) ? 'This field is required' : null;
          break;
        }
        case 'type': {
          if(entryValue === 'list'){
            response = value && !Array.isArray(value)
              ? `Value must be a list, not a ${typeof value}`
              : null;
          } else {
            response =
                  value && typeof value !== entryValue
                    ? `Value must be a ${entryValue}, not a ${typeof value}`
                    : null;
          }
          break;
        }
        case 'min': {
          if(Array.isArray(value)){
            response =
              value.length < entryValue
                ? `Value must be at least ${entryValue}`
                : null;
          } else {
            response =
                  value?.trim() && value.toString().length < entryValue
                    ? `Value must be at least ${entryValue} characters`
                    : null;
  
          }
          break;
        }
        case 'max': {
          response =
                value?.trim() && value.toString().length > entryValue
                  ? `Value should be at greater than ${entryValue} characters`
                  : null;
          break;
        }
        default: {
          break;
        }
        }
        if (response) {
          errorMessage.push(response);
        }
      });
    
      return {
        isValid: !errorMessage.length,
        errorMessage: errorMessage.join(', ')
      };
    } else if (isRequired && !value) {
      return {
        isValid: false,
        errorMessage: 'This field is required'
      };
    } else {
      return {
        isValid: true,
        errorMessage: ''
      };
    }
  };
  
  const validateFormData = (form) => {
    if(!form.fields){
      throw new Error('No form fields found');
    }
    const fields = Object.keys(form.fields);
    fields.forEach(fieldName => {
      const field = form.fields[fieldName];
      const validationResponse = validateFieldData(field.checks, field.value);
      field.isValid = validationResponse.isValid;
      field.errorMessage = validationResponse.errorMessage;
    });
    const isValid = fields.every(field => form.fields[field].isValid);
    
    return {
      isValid,
      errorMessage: isValid? '' : 'Please make sure you have entered all required fields'
    };
  };
  
  export { isValidEmailAddress, validateData2, validateFieldData, validateFormData };
  