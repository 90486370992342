import Service from './Service';



class Session extends Service {
  async createSession(username, password) {
    const credentials = {
      username: username,
      password: password
    };
    return await this.httpClient.post(`/login`, credentials);
  }

  async getPageStatus(pageName) {
    return await this.httpClient.get(`/statusCheck?pageName=${pageName}`);
  }
}
export default Session;
