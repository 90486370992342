import Service from './Service';

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer 9229ud7ivimfsotcboatboej0',
  },
  mode: 'Cors',
  withCredentials: true
};

class Charts extends Service {
  async getDashboardReports(payload) {
    return await this.httpClient.post(`/data/complex_query`, payload);
  }

  async getQueryId(query, config) {
    return await this.httpClient.get(`http://https://botanalytics.intentico.ai/backend/:8071/query?text=${query}`, config);
  }

  async getGraphDetailsFromQueryId(queryId){
    return await this.httpClient.get(`http://https://botanalytics.intentico.ai/backend/:9047/api/v3/job/${queryId}/results`);
  }

  async getSessionIdForLabel(){
    return await this.httpClient.get(`http://https://botanalytics.intentico.ai/backend/:8071/get_intent_list`, defaultConfig);
  }

  async getDataFromSessionId(sessionId){
    return await this.httpClient.get(`http://https://botanalytics.intentico.ai/backend/:8071/getresult?session_id=${sessionId}`, defaultConfig);
  }
}
export default Charts;
