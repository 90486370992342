import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Spinner } from './../assets/images/Spinner.svg';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  backgroundColor: #afafaf;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg{
  }
`

const SplashLoader = () => {
    return <Wrapper><Spinner /></Wrapper>;
}

export default SplashLoader;

