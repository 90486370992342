import { Box, Stack, Typography, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Button from '../../custom/Button';
const Wrapper = styled(Box)(({theme})=> {
    return ({
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .scream': {
            fontSize: 244,
            fontWeight: 'bold',
            lineHeight: '300px',
            background: `url('https://as2.ftcdn.net/v2/jpg/05/86/31/99/1000_F_586319922_hbcPQq5RtVGAVyu2xe9u4MxoGBhRNQId.jpg') 100% 100% no-repeat`,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            color: 'transparent'
        },
        '& .title': {
            fontSize: 68,
            lineHeight: '70px',
            fontWeight: 'bold',
        },
        '& .subtitle': {
            fontSize: 32,
        },
        '& .test': {
            fontSize: 16,
        }
    })
})

const PageNotFound = () => {
    return (
        <Wrapper>
            <Stack gap={1} alignItems={"center"}>
                <Typography className="scream">Oops!</Typography>
                <Typography className="title">404</Typography>
                <Typography className="subtitle">Page Not Found</Typography>
                <Typography className="text">Sorry, we cant find the page you are looking for.</Typography>
                <Button component={NavLink} to='' variant="contained" color="primary">Back to Home</Button>
            </Stack>
        </Wrapper>
    )
}

export default PageNotFound;