import { Alert, AlertTitle, Box, Button, CircularProgress, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, Link, Stack, TextField, Tooltip, Typography, styled } from '@mui/material';
import { Eye, EyeOff } from 'lucide-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/app.slice';
import Session from '../../services/Session.service';
import { validateFieldData } from '../../utils/form.validation';
import BackgroundIllustation from './../../assets/images/illustration_dashboard.png';
const Wrapper = styled(Box)(({ theme }) => {
    return ({
        alignItems: 'center',
        justifyContent: 'center',
        width: '420px',
        borderRadius: '8px',
        '& .MuiFormLabel-root': {
            marginBottom: '10px',
        }
    })
})

const Login = () => {
    const [formData, setFormData] = React.useState({
        formName: 'login',
        formId: 'session_login_form_data',
        fields: {
            username: {
                name: 'username',
                label: 'Username',
                placeholder: '',
                value: '',
                message: null,
                isValid: false,
                checks: {
                    isRequired: true,
                    type: 'string',
                }
            },
            password: {
                name: 'password',
                label: 'Password',
                placeholder: '',
                value: '',
                message: null,
                isValid: false,
                isMasked: true,
                checks: {
                    isRequired: true,
                    type: 'string',
                }
            }
        },
        message: null,
    });
    const [loading, setLoading] = React.useState(true);
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    // const toast = useToast();

    React.useEffect(() => {
        setLoading(false);
    }, [])

    const handleOnBlur = (event) => {
        const { name, value } = event.target;
        const checks = formData.fields[name].checks;
        const res = validateFieldData(checks, value);
        const field = formData.fields[name]
        field.isValid = res.isValid;
        field.message = {
            kind: 'error',
            text: res.errorMessage,
        }
        setFormData({
            ...formData,
        });
    }
    const handleOnFocus = (event) => {
        const { name } = event.target;
        const field = formData.fields[name]
        field.isValid = false;
        field.message = null;
        setFormData({
            ...formData,
        });
    }
    const handleOnChange = (event) => {
        const { name, value } = event.target;
        const field = formData.fields[name]
        field.value = value;

        if (value) {
            field.message = null;
        }

        setFormData({
            ...formData,
        });
    }
    const handleOnTogglePasswordVisibility = () => {
        const { password } = formData.fields;
        password.isMasked = !password.isMasked;
        setFormData({ ...formData });
    }

    const handleOnSubmit = () => {
        const { username, password } = formData.fields;
        if (username.isValid && password.isValid) {
            setLoading(true);
            const session = new Session();
            session.createSession(username.value, password.value)
                .then(response => {
                    if (response.status) {
                        const {
                            token = (new Date()).toString(32),
                            config,
                            default_page,
                            page_name,
                            show_live_option,
                            show_mobile_option
                        } = response;
                        const setting = {
                            config,
                            default_page,
                            page_name,
                            show_live_option,
                            show_mobile_option
                        }
                        dispatch(login({ token, setting }))
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            formData.message = {
                text: 'Make sure you have enetered the correct required details',
                kind: 'error',
            }
            setFormData({ ...formData });
        }
    }

    const { username, password } = formData.fields;
    return (

        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                width: '100%',
            }}
        >
            <Grid container alignItems={"stretch"}>
                <Grid item sm={6}  display={'flex'} alignItems={"center"} justifyContent={"center"}>
                    <img style={{
                        width: "90%",
                        minWidth: "320px",
                    }} src={BackgroundIllustation} alt="Login Background" />
                </Grid>
                <Grid  item sm={6} display={'flex'} alignItems={"center"} justifyContent={"center"}>
                        <Wrapper>
                            <Stack className='form' gap={3}>
                                <Stack gap={1}>
                                    <Typography color={"primary"} variant="h3">Login to Intentico Botlytics</Typography>
                                </Stack>
                                {
                                    formData.message &&
                                    <Alert severity={formData.message.kind}>
                                        <AlertTitle>Error</AlertTitle>
                                        <Typography>{formData.message.text}</Typography>
                                    </Alert>
                                }
                                <Stack gap={3}>
                                    <FormControl>
                                        <FormLabel>{username.label}</FormLabel>
                                        <TextField
                                            name={username.name}
                                            placeholder={username.placeholder}
                                            value={username.value}
                                            error={Boolean(username.message?.text && username.message?.kind === 'error')}
                                            onBlur={handleOnBlur}
                                            onChange={handleOnChange}
                                            onFocus={handleOnFocus}
                                            size="small"
                                        />
                                        <FormHelperText color={username.message?.kind}>{username.message?.text}</FormHelperText>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>{password.label}</FormLabel>
                                        <TextField
                                            name={password.name}
                                            placeholder={password.placeholder}
                                            value={password.value}
                                            error={Boolean(password.message?.text && password.message?.kind === 'error')}
                                            onBlur={handleOnBlur}
                                            onChange={handleOnChange}
                                            onFocus={handleOnFocus}
                                            size="small"
                                            type={password.isMasked ? 'password' : 'text'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={handleOnTogglePasswordVisibility} edge={"start"} size="small">
                                                            {
                                                                password.isMasked ?
                                                                    <Tooltip title={'Show Password'}>
                                                                        <Eye size={18} />
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title={'Hide Password'}>
                                                                        <EyeOff size={18} />
                                                                    </Tooltip>
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <FormHelperText color={password.message?.kind}>{password.message?.text}</FormHelperText>
                                    </FormControl>
                                </Stack>
                                <Stack gap={1}>
                                    <Button disabled={loading} color='primary' variant="contained" onClick={handleOnSubmit} >
                                        {
                                            loading ?
                                                <CircularProgress sx={{ m: '4px', color: '#ffffffae' }} size={"16.5px"} />
                                                :
                                                "Login"
                                        }
                                    </Button>
                                </Stack>
                            </Stack>
                        </Wrapper>
                    
                </Grid>
            </Grid>
        </Box>
    )
}

export default Login;