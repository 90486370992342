import {
  AddCircleOutline,
  CheckCircleOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { Chip, IconButton, Stack, Typography, styled } from "@mui/material";
import React from "react";

const StyledChip = styled(Chip)(({ theme }) => {
  return {
    cursor: "pointer",
    "& input": {
      border: "none",
      outline: "none",
      backgroundColor: "transparent",
    },
  };
});

const StyledChipNew = styled("div")(({ theme, mode, isChecked }) => {
  return {
    cursor: "pointer",
    border: `1px solid ${isChecked ? "#0052CC" : mode ? "#0052CC" : "#bfbfbf"}`,
    backgroundColor: isChecked ? "#0052CC" : "transparent",
    color: isChecked ? "#fff" : "#bfbfbf",
    borderRadius: "14px",
    display: "flex",
    fontSize: "14px",
    paddingLeft: "6px",
    "& .MuiIconButton-root": {
      padding: "2px",
    },
    "& .content": {
      "& .input": {
        display: mode ? "flex" : "none",
        alignItems: "center",
        "& input": {
          color: isChecked ? "#fff" : "#bfbfbf",
          border: "none",
          outline: "none",
          minwidth: "unset",
          width: "auto",
          fontSize: "14px",
          backgroundColor: "transparent",
          color: mode ? "#0052CC" : "#bfbfbf",
          "&::-webkit-input-placeholder": {
            color: "#bfbfbf",
            opacity: 0.9,
          },
          "&::-moz-input-placeholder": {
            color: "#bfbfbf",
            opacity: 0.9,
          },
          "&::-ms-input-placeholder": {
            color: "#bfbfbf",
            opacity: 0.9,
          },
          "&::-o-input-placeholder": {
            color: "#bfbfbf",
            opacity: 0.9,
          },
        },
        "& svg": {
          fill: mode ? "#0052CC" : "#bfbfbf",
        },
      },
      "& .label": {
        alignItems: "center",
        fontSize: "12px",
        color: isChecked ? "#fff" : "#bfbfbf",
        display: !mode ? "flex" : "none",
        "& svg": {
          fill: isChecked ? "#fff" : "#bfbfbf",
        },
      },
    },
  };
});

const EntityChip = ({ entity, addNewEntity, removeEntity }) => {
  const [isTakingNewValue, setIsTakingNewValue] = React.useState(false);
  const [newValue, setValue] = React.useState("");
  const inputField = React.useRef();

  let [label, value] = entity;
  if (Array.isArray(value)) value = value[0];

  const handleOnInputAction = (state) => {
    if (state) {
      setIsTakingNewValue(true);
      if (inputField.current) inputField.current.focus();
    } else {
      addNewEntity(label, newValue);
      setIsTakingNewValue(false);
      setValue("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addNewEntity(label, newValue);
      setIsTakingNewValue(false);
      setValue("");
    }
  };

  return (
    <Stack>
      <StyledChipNew
        isChecked={Boolean(value)}
        mode={Boolean(isTakingNewValue)}
      >
        <Stack flexGrow="1" className="content">
          <Stack className="label" direction="row" gap={0.5}>
            <Typography variant="body2">
              {label} <span className="highlight">{value}</span>
            </Typography>
            {value ? (
              <IconButton size="small" onClick={() => removeEntity(label)}>
                <RemoveCircleOutline fontSize={"10"} />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={handleOnInputAction}>
                <AddCircleOutline fontSize={"10"} />
              </IconButton>
            )}
          </Stack>
          <Stack className="input" direction="row">
            <input
              style={{
                minWidth: "40px",
              }}
              onKeyPress={handleKeyPress}
              onBlur={(e) =>
                newValue
                  ? handleOnInputAction(false)
                  : setIsTakingNewValue(false)
              }
              onChange={(e) => setValue(e.target.value)}
              type="text"
              placeholder={label}
              value={newValue}
              ref={(input) => input && input.focus()}
            />
            <IconButton size="small" onClick={() => handleOnInputAction(false)}>
              <CheckCircleOutline fontSize={"8"} />
            </IconButton>
          </Stack>
        </Stack>
      </StyledChipNew>
    </Stack>
  );
};

export default EntityChip;
