import { createSlice } from '@reduxjs/toolkit';
import Storage from '../utils/session.util';
const initialState = {
    isLoading: false,
    token: null,
    setting: null,
    module: 'clare',
}
const appSlice = createSlice({
    name: 'appSlice',
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            const {token, setting} = action.payload;
            Storage.token = token;
            Storage.setting = setting;
            state.token = token;
            state.setting = setting;
        },
        logout: (state, action) => {
            Storage.removeStorage();
            state.token = null;
            state.setting = null;
        },
    },
});

export default appSlice;
export const { login, logout, changeModule } = appSlice.actions;

