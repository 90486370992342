import { styled } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

const TextField = styled(MuiTextField)((props)=>{
    const {theme, color, variant} = props;

    let backgroundColor = '#ffffff';
    let foregroundColor = '#656f89';
    let borderColor = '#656f89';
    let borderRadius = '6px';
    
    if(color){
        foregroundColor = '#ffffff';
        backgroundColor = theme.palette[color].main;
    }

    if( variant === "standard"){
        if(color){
            foregroundColor =  '#fff';
            backgroundColor = theme.palette[color].main;
            borderColor= 'transparent';
        }else{
            foregroundColor = '#fff';
            backgroundColor= backgroundColor;
            borderColor = 'transparent';
        }
    }
    else if( variant === "outlined"){
        if(color){
            foregroundColor =  theme.palette[color].main;
            backgroundColor = 'transparent';
            borderColor=  theme.palette[color].main;
        }else{
            foregroundColor = '#656f89';
            borderColor = '#656f89';
            backgroundColor = `${backgroundColor}14`;
        }
    } else if ( variant === "filled"){
            borderRadius = '8px';
            if(color){
                foregroundColor =  theme.palette[color].main;
                backgroundColor = `transparent`;
                borderColor =  theme.palette[color].main;
            }else{
                foregroundColor = '#656f89';
                borderColor = backgroundColor;
                backgroundColor = `transparent`;
            }
    }
    else {
        if(color){
            foregroundColor =  theme.palette[color].main;
            backgroundColor = `${ theme.palette[color].main}14`;
            borderColor =  theme.palette[color].main;
        }else{
            foregroundColor = '#656f89';
            borderColor =  `#656f8934`;;
            backgroundColor = `transparent`;
        }
    }

    return ({
        '& legend span': {
            marginRight: '-10px',
        },
        '&.MuiTextField-root': {
            '& .MuiFormLabel-root.Mui-focused': {
                color: `${foregroundColor}`
            },
            '& .MuiFormLabel-root': {
                color: `${foregroundColor}64`
            },
        },
        
        '& .MuiInputBase-root': {
            borderRadius:borderRadius,
            '&.Mui-focused': {
                borderWidth: '1px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${borderColor}64`,
                backgroundColor: backgroundColor,
            },
            '& .MuiInputBase-input': {
               color: foregroundColor,
               '&:hover.MuiFilledInput-root::after': {
                    borderWidth: '0px',
                }
            },
            '&.MuiFilledInput-root::before': {
                borderWidth: '0px',
            },
            '&.MuiFilledInput-root::after': {
                borderWidth: '0px',
            },

            '& input': {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 30px #ffffffff inset !important'
                },
                '&:-webkit-autofill:hover': {
                    WebkitBoxShadow: '0 0 0 30px #ffffffff inset !important'
                },
                '&:-webkit-autofill:focus': {
                    WebkitBoxShadow: '0 0 0 30px #ffffffff inset !important'
                },
                '&:-webkit-autofill:active': {
                    WebkitBoxShadow: '0 0 0 30px #ffffffff inset !important'
                },
                '&[type=password]': {
                    letterSpacing: '4px',
                    '&::-ms-input-placeholder': {
                        letterSpacing: 'initial',
                    },
                    '&::placeholder': {
                        letterSpacing: 'initial',
                    }
                },
            },
        },
        '& .MuiInputBase-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${borderColor}`,
            },
        },
        '& .MuiInputBase-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${borderColor}`,
            },
        }
    })
});

export default TextField;